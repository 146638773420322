import React from 'react'

import Footer from '~/components/Footer/FooterGql'
import WeOfferSection from '~/components/landing-common/WeOfferSection'
import ExplanationSection from '~/components/landing-common/ExplanationSection'
import HeroSection from '~/components/landing-common/HeroSection/HeroSectionGql'
import TakePartSection from '~/components/landing-common/TakePartSection/TakePartSectionGql'
import VoucherSection from '~/components/landing-common/VoucherSection'
import InstagramSection from '~/components/landing-common/InstagramSection'
import LogosSection from '~/components/LogosSection/LogosSectionGql'

const OFFERS = {
  offer_1: 'percent',
  offer_2: 'book',
  offer_3: 'cutlery',
  offer_4: 'reservation',
  offer_5: 'glass',
  offer_6: 'time'
}

const SOCIALS = [
  {
    src: 'instagram.png',
    href: 'https://www.instagram.com/grimbergenpl/'
  },
  {
    src: 'facebook.png',
    href: 'https://www.facebook.com/grimbergenpl/'
  },
]

export default class Index extends React.Component {

  render () {
    const { isVoucher } = this.props

    return <div>
      <div className='container'>
        <HeroSection slides={['hero-bfw_01.jpg', 'hero-bfw_02.jpg']} />
      </div>
      <div className='container'>
        <ExplanationSection />
      </div>
      {isVoucher &&
      <div className='container'>
        <VoucherSection />
      </div>}
      <div className='container'>
        <WeOfferSection
          offers={OFFERS}
          decorator='landing-bfw/we-offer/decorator.png'
        />
      </div>
      <InstagramSection socials={SOCIALS} instaFeed={null} />
      <div className='container'>
        <LogosSection loadImmediately />
      </div>
      {!isVoucher && <TakePartSection modifiers='index' withRespect={false} />}
      <Footer />
    </div>
  }
}
